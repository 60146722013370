<template>
  <div>
    <div v-if="!addOrUpdate" align="center" class="mt-3 mb-3">
      <button class="btn align-bottom button-add-carburant" @click="addFuelFill">Ajouter un plein</button>
    </div>
    <FuelFillEdit :item="this.itemToUpdate" @clicked="addOrUpdate = false" v-if="addOrUpdate" />
    <button v-if="!loadPage" class="btn btn-primary" type="button" disabled>
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Chargement des données en cours...
    </button>
    <table v-show="loadPage" class="table mt-1">
      <thead class="thead-light">
        <tr>
          <th scope="col" class="resize-date">{{$t('fuelFills.edit.date')}}</th>
          <th scope="col">{{$t('fuelFills.edit.gas_pump')}}</th>
          <th scope="col" class="resize-quantity">{{$t('fuelFills.edit.quantity')}}</th>
        </tr>
      </thead>
      <tbody v-for="item in fuelFillsToPatch" v-bind:key="item.id">
        <tr>
          <td>
            {{ formatDate(item.doneDate, "date") }}
            <br />
            <span
              class="resize-hours"
            >{{getHoursMinutes(item.doneDate)}}</span>
          </td>
          <td>{{limitCharacters(item.location_name)}}</td>
          <td>
            <span
              :class="[
                        { 'cell-warning': !item.full },
                      ]"
              v-b-tooltip.hover.top.html.window=" !item.full ? $t('fuelFills.list.warning_partial_fuel') : ''"
            >
              {{item.quantity}}
              <span v-if="item.quantity!=null">L</span>
            </span>
            <br />
            <button class="btn align-right update-delete-buttons" @click="onDelete(item.id)">
              <img :src="deleteIcon" alt="icon delete action" />
            </button>
            <button class="btn align-right update-delete-buttons" @click="updateFuelFill(item)">
              <img :src="updateIcon" alt="icon update action" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import delIcon from "@/assets/images/delete_icon.svg";
import updIcon from "@/assets/images/update.svg";
import FuelFillEdit from "@/components/machines/edit/FuelFillEdit.vue";
import { askConfirm, formatDate } from "@/utils/commons";

export default {
  name: "fuel-fill",
  data() {
    return {
      deleteIcon: delIcon,
      updateIcon: updIcon,
      loadPage: false,
      addOrUpdate: false,
      itemToUpdate: {}
    };
  },
  components: {
    FuelFillEdit
  },
    created() {
    this.init();
  },
  methods: {
    init: async function() {
      // Load all fuelFills of the current Machine
      await this.$store.dispatch("location/getGasPumps");
      this.loadPage = false;
      await this.$store.dispatch("fuelFill/getFuelFillsByMachine", {machine : this.currentMachine.id});
      
      this.loadPage = true;
    },
    formatDate,
    getHoursMinutes(date) {
      var dateToFormat = new Date(date);
      var hh = parseFloat(dateToFormat.getHours()) <= 9 ? '0'+dateToFormat.getHours(): dateToFormat.getHours();
      var min = parseFloat(dateToFormat.getMinutes()) <= 9 ? '0'+dateToFormat.getMinutes(): dateToFormat.getMinutes();
      dateToFormat = hh + ':' + min;
      if(dateToFormat === '00:00') return;
      return(dateToFormat);
    },
    limitCharacters(str) {
      if(str.length == 0) return '';
      if(str.length <=20 ) return str;
      return str.substring(0,20)+'...';
    },
    addFuelFill() {
      this.addOrUpdate = !this.addOrUpdate;
      this.itemToUpdate = {};
    },
    updateFuelFill(item) {
      this.addOrUpdate = !this.addOrUpdate
      this.itemToUpdate = item;
      //this.loadDetails = !this.loadDetails
    },
    async onDelete(fuelFillId) {
      if(await askConfirm(this.$t("confirm_delete")))
      {
        await this.$store.dispatch("fuelFill/deleteFuelFill", {id : fuelFillId});
        this.init();
      }
    },
  },
  computed: {
    ...mapGetters({ 
      currentMachine: "machine/currentEntry",
      fuelFills: "fuelFill/fuelFills",
      gasPumps: "location/gasPumps",
     }),
    fuelFillsToPatch() {
      var fuelFillClone = JSON.parse(JSON.stringify(this.fuelFills));
      fuelFillClone.map(element => {
        var filtredData = this.gasPumps.filter(e => e.id == element.location)
        if(filtredData.length != 0) element.location_name = filtredData[0].name;
        else element.location_name = '';
      })
      fuelFillClone.sort(function(a, b) {
        var c = new Date(a.doneDate);
        var d = new Date(b.doneDate);
        return d-c;
      });
      return fuelFillClone;
    },
  },
};
</script>

<style lang="scss" scoped>
/**
 * Params button carburant .
 */
.button-add-carburant {
  background-color: $background-text-color;
  width: 175px;
}

.button-add-carburant:hover {
  background: $secondary-light-color;
}

table {
  border-collapse: separate;
  border-spacing: 0 5px;
}

table > thead > tr,
th {
  font-size: 0.9em;
}

table > tbody > tr,
td {
  font-size: 0.87em;
  padding-bottom: 0rem!important;
}

.resize-date {
  width: 26.9%;
}

.resize-quantity {
  width: 26%;
}

.resize-hours {
  font-size: 0.92em;
}
</style>
