<template>
  <div>
    <b-row class="mt-2">
      <b-col cols="6">
        <h4 class="subtitle-form overload-subtitle mb-2">{{$t('fuelFills.edit.quantity')}}</h4>
      </b-col>
      <b-col cols="6">
        <h4 class="subtitle-form overload-subtitle mb-2">{{$t('fuelFills.edit.full')}}</h4>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
          <NumericInput
                inputRef="input"
                :value="selectedQuantity"
                @input="i => {selectedQuantity = i}"
                :required=true
                :numberOfDigits="2"
          />
      </b-col>
      <b-col cols="6">
        <div class="custom-control custom-switch">
          <input
            :checked="fullChoice"
            type="checkbox"
            class="custom-control-input"
            id="customSwitch1"
            @click="switchFullChoice"
          />
          <label class="custom-control-label" for="customSwitch1"></label>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col cols="12">
        <h4 class="subtitle-form overload-subtitle mb-0">{{$t('fuelFills.edit.date_hour')}}</h4>
      </b-col>
    </b-row>
    <b-row class="row-resize mt-1">
      <!-- Date Text-->
      <b-col cols="6" class="text-infos shape-first-cell whitout-bg">
        <img :src="iconCalendar" alt="calendar" />
        <span class="text-activity-notes subtitle-params">{{$t('fuelFills.edit.date')}}</span>
      </b-col>
      <!-- hour text-->
      <b-col cols="6" class="text-infos shape-second-cell whitout-bg">
        <img :src="iconClock" alt="clock" />
        <span class="text-activity-notes subtitle-params">{{$t('fuelFills.edit.hour')}}</span>
      </b-col>
    </b-row>

    <b-row class="row-resize mb-2">
      <!-- Date Input-->
      <b-col cols="6" class="text-infos shape-first-cell whitout-bg">
        <div class="inputs-params">
          <input type="date" ref="fullDate" id="example-datepicker" v-model="fullDate" placeholder="yyyy-mm-dd" required/>
        </div>
      </b-col>
      <!-- hour input -->
      <b-col cols="6" class="pr-3 text-infos shape-second-cell whitout-bg">
        <div class="inputs-params">
          <b-input type="time" ref="time" v-model="time" size="sm" placeholder="hh:mm"/>
        </div>
      </b-col>
    </b-row>

    <h4 class="subtitle-form overload-subtitle mb-2">{{$t('fuelFills.edit.gas_pump')}}</h4>
    <b-row class="mb-4">
      <b-col cols="12">
        <Dropdown
          ref="dropdown"
          class="shape-custom-select"
          label="name"
          :value="this.selectedPump"
          :options="gasPumps"
          @input="selectedInput => selectedPump=selectedInput"
        />
      </b-col>
    </b-row>

    <div align="center" class="mb-3">
      <button class="btn align-bottom button-add-carburant" @click="onSave" type="submit">Enregistrer</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import calendar from "@/assets/images/calendar.svg";
import clock from "@/assets/images/clock.svg";
import Dropdown from "@/components/machines/details/Dropdown.vue";
import {checkDatePattern, checkTimePattern} from "@/utils/commons";
import NumericInput from "@/components/commons/NumericInput.vue";

export default {
  name: "fuelFill-edit",
  created() {
    this.init()
  },
  data() {
    return {
        iconClock: clock,
        iconCalendar: calendar,
        selectedQuantity: null,
        fullChoice: true,
        fullDate: '',
        time: '',
        selectedPump: {},
        itemToUpdate: {},
        add: true,
    };
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Dropdown,
    NumericInput
  },
  methods: {
    async init() {
      await this.$store.dispatch("location/getGasPumps");
      if(Object.keys(this.item).length != 0)
      {
        let date = new Date(this.item.doneDate);
        let yyyy = date.getFullYear();
        let tmpMm = date.getMonth()+1;
        let mm = parseFloat(tmpMm) <=9 ? '0'+tmpMm : tmpMm;
        let tmpDd = date.getDate();
        let dd = parseFloat(tmpDd) <=9 ? '0'+tmpDd : tmpDd;
        this.fullDate =  yyyy+'-' + mm + '-'+dd;//prints expected format.
        let hours = parseFloat(date.getHours())<=9 ? "0"+date.getHours() : date.getHours();
        let minutes = parseFloat(date.getMinutes())<=9 ? "0"+date.getMinutes() : date.getMinutes()
        this.time = hours === "00" && minutes === "00"? null : hours+":"+minutes;
        this.fullChoice = this.item.full;
        this.selectedQuantity = this.item.quantity;
        let filteredPumps = this.gasPumps.filter(element => element.id == this.item.location);
        if(filteredPumps.length != 0) this.selectedPump = filteredPumps[0];
        this.add = false;
      }
    },
    /**
     * Description: switch the 'fullChoice' input
     */
    switchFullChoice() {
      this.fullChoice = !this.fullChoice;
    },
    /**
     * Description: return date
     */
    getDate() {
      let TimeArray = this.time != null ? this.time.split(':') : []
      let hours = (TimeArray[0] != undefined && TimeArray[0] != '') ? TimeArray[0] : 0;
      let minutes = (TimeArray[1] != undefined && TimeArray[1] != '') ? TimeArray[1] : 0;

      var date = new Date(this.fullDate);
      date.setHours(hours, minutes);
      if (date != "Invalid Date") return date.toISOString();
      return null;
    },
    /**
     * Description: save the fuelfill
     */
    async onSave() {
      if(!checkDatePattern(this.fullDate) || !checkTimePattern(this.time) || !this.selectedQuantity)
        return;
      let dataToPatch = {
        doneDate: this.getDate()!=null? this.getDate():'',
        full: this.fullChoice,
        quantity: this.selectedQuantity == null || this.selectedQuantity === "" ? null: this.selectedQuantity,
        machine: this.currentMachine.id,
        location: this.selectedPump.id
      }
      if(this.add == true)
      {
        await this.$store.dispatch("fuelFill/createFuelFill", {
          data: dataToPatch,
        });
      }
      else {
        await this.$store.dispatch("fuelFill/updateFuelFill", { id: this.item.id,
          data: dataToPatch,
        });
      }
      await this.$store.dispatch("fuelFill/getFuelFillsByMachine", {machine : this.currentMachine.id});
      this.$emit('clicked', '')
    },
  },
  computed: {
    ...mapGetters({
      currentMachine: "machine/currentEntry",
      gasPumps: "location/gasPumps",
    }),
  },
  watch: {
    fullDate: {
      handler: async function() {
        if(!checkDatePattern(this.fullDate)) {
          this.$refs.fullDate.setCustomValidity(this.$i18n.t("commons.details.datePatternError"));
        }else{
          this.$refs.fullDate.setCustomValidity("");
        }
      }
    },
    time: {
      handler: async function() {
        if(!checkTimePattern(this.time)) {
          this.$refs.time.setCustomValidity(this.$i18n.t("commons.details.timePatternError"));
        }else{
          this.$refs.time.setCustomValidity("");
        }
      }
    },
  }
};
</script>

<style lang="scss" scoped>
/**
 * Date params.
 */
.inputs-date {
  margin: 2px 10px 3px 0px;
  // width: 47%;
}

img {
  width: 1.5em;
  float: left;
  margin: auto 5px 5px auto;
}

/**
 * Resize rows.
 */
.row-resize {
  padding-left: 16px;
}

.button-add-carburant {
  background-color: $secondary-light-color;
  width: 175px;
}
</style>
